import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, Row, Col } from "react-styled-flexboxgrid";
import media from "../tools/mediaQueries";
import { graphql } from "gatsby";
import Layout from "../layouts";
import Hero from "../components/Hero";

const makeKey = (string, i) => string.slice(1, 5) + i;

const Fees = ({ data }) => {
	const { court, collection } = data.allFeesJson.edges[0].node;

	return (
		<Layout>
			<Grid>
				<Helmet title="STERN & STERN - Fees" />
				<StyledRow>
					<Hero title="STANDARD LEGAL fees" />
					<Lead xs={12}>
						<h1>EFFECTIVE as of July 2022</h1>
					</Lead>
				</StyledRow>
				<StyledRow>
					<Title xs={12} sm={7} smOffset={1}>
						<h5>Court Costs* • New York State</h5>
					</Title>
					{court.map((item, i) => {
						const id = makeKey(item.name, i);
						return (
							<Col xs={12} key={id}>
								<Row>
									<Name xs={8} sm={7} smOffset={1}>
										<p>{item.name}</p>
									</Name>
									<Price xs={4} sm={3}>
										<p>{item.price}</p>
									</Price>
								</Row>
							</Col>
						);
					})}
				</StyledRow>
				<StyledRow>
					<Title xs={12} sm={7} smOffset={1}>
						<h5>Collection Costs*</h5>
					</Title>
					{collection.map((item, i) => {
						const id = makeKey(item.name, i);
						return (
							<Col xs={12} key={id}>
								<Row>
									<Name xs={8} sm={7} smOffset={1}>
										<p>{item.name}</p>
									</Name>
									<Price xs={4} sm={3}>
										<p>{item.price}</p>
									</Price>
								</Row>
							</Col>
						);
					})}
				</StyledRow>
				<StyledRow>
					<Info xs={12}>
						<p>
							*All costs with the exception of Restraining Notices and Credit
							Report are the exact amount charged by the Court or Agency. Stern
							& Stern does not charge any additional legal fee. Sheriff and
							Marshal also get a fee of 5% of amount collected. This is added to
							debtor balance at their office.
						</p>
					</Info>
				</StyledRow>
			</Grid>
		</Layout>
	);
};

export const query = graphql`
	query FeesPage {
		allFeesJson {
			edges {
				node {
					court {
						name
						price
					}
					collection {
						name
						price
					}
				}
			}
		}
	}
`;

Fees.propTypes = {
	data: PropTypes.shape({}).isRequired
};

export default Fees;

const StyledRow = styled(Row)`
	background-color: #fff;
	padding-bottom: 30px;
`;

const Lead = styled(Col)`
	text-align: center;

	h1 {
		line-height: 34px;
		font-size: 22px;
		font-family: "Montserrat";
		font-weight: 700;
		margin: 10px 0 30px 0;
		color: #4b721d;
		text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
		text-transform: uppercase;

		${media.sm`
      font-size: 2.125rem;
      margin-bottom: 40px;
    `};

		${media.md`
      font-size: 36px;
    `};
	}
`;

const Title = styled(Col)`
	font-family: "Montserrat";

	h5 {
		color: #a85701;
		font-size: 17px;
		font-weight: 700;
		line-height: 22px;
	}
`;

const Name = styled(Col)`
	font-family: "Montserrat";
	font-weight: 500;
	padding-bottom: 20px;

	${media.sm`
    padding-bottom: 0;
  `};

	p {
		line-height: 18px;
		font-size: 12px;
		margin-bottom: 0;

		${media.sm`
      line-height: 20px;
      font-size: 16px;
    `};
	}
`;

const Price = styled(Col)`
	font-family: "Montserrat";
	font-weight: 500;
	text-align: right;
	padding-bottom: 20px;

	${media.sm`
    text-align: left;
    padding-bottom: 0;
  `};

	p {
		line-height: 20px;
		font-size: 16px;
		margin-bottom: 0;
	}
`;

const Info = styled(Col)`
	text-align: center;
	font-family: "Montserrat";
	font-weight: 500;
	margin: 30px 0;

	p {
		text-align: center;
		font-size: 14px;
		line-height: 20px;
		margin: 0 auto;

		${media.sm`
      width: 70%;
    `};
	}
`;
